<template>
  <div class="bg">
        <div class="music">
          <audio
              autoplay="autoplay"
              controls="controls"
              loop="loop"
              :src=musicUrl
          >
            你的浏览器版本太低了
          </audio>
        </div>

    <nav>
      <router-link to="/">Snake</router-link>
      |
      <router-link to="/about">About</router-link>
    </nav>
    <!-- router-view的作用就是直接将组件的内容展示在下面，
    根据上面的点击的
    路由跳转到相应的组件 -->
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      musicUrl: require('./assets/music/gnypmfys.mp3')
    }
  },
}
</script>
<style lang="less">

nav {
  text-align: center;
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.music {
  display: inline;
}

.words {
  display: inline;
  position: relative;
  bottom: 20px;
}

#app {
  // 这个路径使用src/assets就不对不知道为什么
  background: url('@/assets/bg.jpg') center;
  background-size: cover;
}
</style>

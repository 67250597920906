<template>
  <div class="game">
    <div class="game-div">
      <div class="game-min">
        <!-- 加冒号表示后面的这个是变量，或者是动态绑定-->
        <div v-for="(e, i) in frame" :key="i" class="row">
          <p v-for="(b, j) in e" :key="j" class="element" :style="{background: b.bg}"></p>
        </div>
      </div>

      <div class="right-div">

        <!--<el-button plain @click="alertLuck">提醒</el-button>-->

        <div class="form">
          <h4>得分</h4>
          <p>{{score}}</p>
        </div>
        <div class="form">
          <h4>等级</h4>
          <p>{{level}}</p>
        </div>
        <div class="form">
          <h4>吃</h4>
          <p>{{eatTimes}}</p>
        </div>
        <button class="ztks" @click="autoMove">
          暂停/开始
        </button>
      </div>
    </div>

    <div class="control-div">
      <div class="control">
        <p @click="moveUp">Up</p>
        <div class="lr">
          <p @click="moveLeft">Left</p>
          <p @click="moveRight">Right</p>
        </div>
        <p @click="moveDown">Down</p>
      </div>

      <!--avatar-->
      <div class="profile">
        <img :src=avatarUrl alt="找不到喽">
      </div>
    </div>

  </div>
</template>

<script>
import {myColors} from "@/utils/SnakeData"
import {ElMessage} from "element-plus";

export default {

  data() {
    return {
      row: 20,
      col: 20,
      frame: [], // 界面
      bg: "#cfeeef", // 默认背景色
      snake: {},
      food: {},
      colors: myColors,

      avatarUrl: require("../assets/4.png"),

      over: false, // false是正常，true是说明game over

      timer: "",
      speed: 501,

      direction: 0, // 方向: 0左 1右 2上 3下

      eatTimes: 0,
      level: 1,
      score: 0,
    }
  },
  methods: {
    // 游戏框架
    gameFrame() {
      for (let i = 0; i < this.row; i++) {
        let a = [];
        for (let j = 0; j < this.col; j++) {
          let b = {
            bg: this.bg,
          };
          a.push(b);
        }
        this.frame.push(a);
      }
    },

    // 初始化蛇
    initSnake() {
      this.snake = {
        site: [9, 8, 9, 9, 9, 10, 9, 11, 9, 12, 9, 13, 9, 14, 9, 15, 9, 16],
        color: '#004b69'
      }
      this.renderBlock(this.snake, this.frame, 1);
    },

    // 方块渲染
    // a:方块， b:渲染的位置，n:类型-0是清除,1是渲染
    // a是要渲染的json对象，包含site和color，可能是snake也可能是food，b是frame棋盘，n是选择渲染还是擦除
    renderBlock(a, b, n) {
      let c = a.site;
      if (n === 1) {
        for (let i = 0; i < c.length; i += 2) {
          b[c[i]][c[i + 1]].bg = a.color;
        }
      } else if (n === 0) {
        for (let i = 0; i < c.length; i += 2) {
          b[c[i]][c[i + 1]].bg = this.bg;
        }
      }
    },

    // 产生食物
    initFood() {
      this.setFoodSite();
      // colors记得要加this否则会提示没有该变量
      this.food.color = myColors[Math.floor(Math.random() * 3)]; // 3是colors拥有的颜色种数
      // console.log(color);
      console.log(this.food.site)
      if (this.food.color === "#cfeeef") {
        this.alertLuck();
      }
      this.renderBlock(this.food, this.frame, 1);
    },

    setFoodSite() {
      this.food.site = [Math.floor(Math.random() * this.row), Math.floor(Math.random() * this.col)];
      for (let i = 0; i <= this.snake.site.length - 2; i += 2) {
        if (this.food.site[0] === this.snake.site[i] &&
            this.food.site[1] === this.snake.site[i + 1]) {
          this.setFoodSite();
        }
      }
    },

    // 如何实现蛇的运动？
    // 只移动第一个元素，其他的后面的元素是变成前一个元素的状态
    move() {
      // i > 1是排除第一个点
      for (let i = this.snake.site.length - 1; i > 1; i -= 2) {
        this.snake.site[i] = this.snake.site[i - 2];
        this.snake.site[i - 1] = this.snake.site[i - 3];
      }
    },

    // 向左
    moveLeft() {
      // 想向左，但如果本来是向右的就不能变，即不执行下面的方法
      if (!this.over && this.direction !== 1) {
        this.direction = 0;

        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.renderBlock(this.snake, this.frame, 0);

          this.eatFood(this.snake.site[0], this.snake.site[1] - 1);

          this.move();
          this.snake.site[1]--;

          this.judgeBlockSelf();

          if (this.snake.site[1] < 0) {
            this.over = true;
            console.log("撞墙了！")
            this.snake.site[1]++;
            // 这一步需要理解下
            // a b c d (move)-> a' a b c -> a a b c所以少了一个点

            clearInterval(this.timer); // 防止一直撞墙
          }
          this.renderBlock(this.snake, this.frame, 1);
        }, this.speed) // speed由等级决定
      }
    },

    moveRight() {
      if (!this.over && this.direction !== 0) {
        this.direction = 1;

        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.renderBlock(this.snake, this.frame, 0);

          this.eatFood(this.snake.site[0], this.snake.site[1] + 1);

          this.move();
          this.snake.site[1]++;

          this.judgeBlockSelf();

          if (this.snake.site[1] > this.col - 1) {
            this.over = true;
            console.log("撞墙了！")
            this.snake.site[1]--;
            clearInterval(this.timer);
          }
          this.renderBlock(this.snake, this.frame, 1);
        }, this.speed) // speed由等级决定
      }
    },

    moveUp() {
      if (!this.over && this.direction !== 3) {
        this.direction = 2;

        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.renderBlock(this.snake, this.frame, 0);

          this.eatFood(this.snake.site[0] - 1, this.snake.site[1]);

          this.move();
          this.snake.site[0]--;

          this.judgeBlockSelf();

          if (this.snake.site[0] < 0) {
            this.over = true;
            console.log("撞墙了！")
            this.snake.site[0]++;
            clearInterval(this.timer);
          }
          this.renderBlock(this.snake, this.frame, 1);
        }, this.speed)
      }
    },


    moveDown() {
      if (!this.over && this.direction !== 2) {
        this.direction = 3;

        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.renderBlock(this.snake, this.frame, 0);

          this.eatFood(this.snake.site[0] + 1, this.snake.site[1]);

          this.move();
          this.snake.site[0]++;

          this.judgeBlockSelf();

          if (this.snake.site[0] > this.row - 1) {
            this.over = true;
            console.log("撞墙了！")
            this.snake.site[0]--;
            clearInterval(this.timer);
          }
          this.renderBlock(this.snake, this.frame, 1);
        }, this.speed)
      }
    },

    // 撞到自己
    judgeBlockSelf() {
      // 头部来与身体对比
      let head = [this.snake.site[0], this.snake.site[1]];
      for (let i = this.snake.site.length - 1; i > 1; i -= 2) {
        if (this.snake.site[i] === head[1] && this.snake.site[i - 1] === head[0]) {
          this.over = true;
          clearInterval(this.timer);
          console.log("撞到自己了")
        }
      }
    },

    // 吃东西
    eatFood(i, j) {
      if (i === this.food.site[0] && j === this.food.site[1]) {
        this.snake.site.unshift(this.food.site[0], this.food.site[1]);

        this.initFood();

        this.eatTimes++;
        this.score += this.level * 100;

        let lev = Math.floor(this.eatTimes / 2) + 1;

        if (lev > this.level) {
          this.level = lev;
          this.speed = 501 - (this.level - 1) * 100;

          // 不调用的话，速度变化会有延迟
          clearInterval(this.timer);
          this.timer = ""

          console.log(this.timer);
          this.autoMove();

        }
      }
    },

    autoMove() {
      console.log(this.timer)
      if (this.timer !== "") {
        console.log('timer=""!');
        clearInterval(this.timer);
        this.timer = '';
      } else { //这里的else是暂停的情况下
        if (this.direction === 0) {
          this.moveLeft();
        } else if (this.direction === 1) {
          this.moveRight();
        } else if (this.direction === 2) {
          this.moveUp();
        } else if (this.direction === 3) {
          this.moveDown();
        }

      }
    },

    alertLuck() {
      ElMessage({
        showClose: true,
        message: '恭喜你，产生了一个跟棋盘颜色相同的食物，祝你尽快找到它！',
        center: true,
        duration: 4000,
        offset: 20,
        type: 'success',
      })
      let elm = document.querySelector('.el-message');
      if (elm) {
        elm.style.fontWeight = "bolder"
      }
    }

  },

  mounted() {
    this.gameFrame();
    this.initSnake();
    this.initFood();

  }
}
</script>

<style lang="less" scoped>
.game {
  padding-bottom: 10%;
  .game-div {
    display: flex;
    justify-content: center;

    .game-min {
      // 要出现方格的效果，关键就是.row的padding-bottom和
      // .element的margin-right要一样大
      .row {
        display: flex;
        padding-bottom: 2px;
        //justify-content: center; // 居中，自己加的
        //这步是把p元素本身带有的一些属性去掉
        // 所以为什么要用p呢，直接还是用div不行？
        p {
          padding: 0;
          margin: 0;
        }

        .element {
          height: 12px;
          width: 12px;
          margin-right: 2px;
        }
      }
    }

    .right-div {
      .form {
        margin-bottom: 35px;
      }

      .ztks {
        margin: 0;
        // 这种形式应该是上下各5 左右10
        padding: 5px 10px;
        font-size: 18px;
        font-family: "楷体", serif;
        font-weight: bold;
        background: #42b983;
        cursor: pointer; // 移动到上面变成手型
      }

      margin-left: 30px;

      h4 {
        margin-bottom: 10px;
        line-height: 6px;
      }

      p {
        padding: 0;
        margin: 0;
      }
    }

  }

  .control-div {
    display: flex;
    justify-content: center;
    height: 200px;

    .control {
      margin-right: 30px;
      padding-top: 10px;
      width: 290px;

      p {
        margin: 10px;
        padding: 8px;
        cursor: pointer;
        background: aquamarine;
        font-family: "Calibri Light", serif;
        font-weight: bold;
        text-align: center;
        min-width: 110px; // 设置最小值，上面的依旧是这么宽
      }

      .lr {
        display: flex;
        justify-content: space-between; // 中间分开，两边和上面对齐
      }
    }

    .profile {

      position: relative;
      left: -5px;

      margin-top: 20px;
      border: 2px solid #381932;
      box-shadow: 0 0 10px #313; // 加阴影
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>